/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'babel-polyfill'
import '@formatjs/intl-unified-numberformat/polyfill'
import '@formatjs/intl-pluralrules/polyfill'; 
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/zh';

import 'core-js' ;

import {UnifiedNumberFormat} from '@formatjs/intl-unified-numberformat';

import IntlRelativeTimeFormat from '@formatjs/intl-relativetimeformat';
// locale-data for zh
IntlRelativeTimeFormat.__addLocaleData(
  require('@formatjs/intl-relativetimeformat/dist/locale-data/zh.json')
);
 
// locale-data for zh
IntlRelativeTimeFormat.__addLocaleData(
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en.json')
);

  UnifiedNumberFormat.__addLocaleData(
    require('@formatjs/intl-unified-numberformat/dist/locale-data/zh.json') // locale-data for zh
  );
  UnifiedNumberFormat.__addLocaleData(
    require('@formatjs/intl-unified-numberformat/dist/locale-data/en.json') // locale-data for en
  );
   
  new UnifiedNumberFormat('zh', {
    style: 'unit',
    unit: 'bit',
    unitDisplay: 'long',
  }).format(1000); // 1,000比特 



// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  // Without this function body the import will not be picked up.
}

